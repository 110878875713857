import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["select", "alternativeFields"]

  connect() {
    this.fieldName = this.selectTarget.name
    this.isRequired = this.selectTarget.required

    this.toggleFields()
  }

  toggleFields() {
    const isShow = this.selectTarget.value === this.element.dataset.showOn

    this.alternativeFieldsTarget.classList.toggle("hidden", !isShow)

    this.alternativeFieldsTargets.forEach(field => {
      const input = field.querySelector('input')
      if (!input) return

      if (isShow) {
        input.name = input.dataset.fieldName
      } else {
        input.name = ""
      }
    })
  }
}
