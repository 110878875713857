import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["copyButton", "copyValue"];

  copy(event) {
    event.stopPropagation();
    this.copyToClipboard();
    this.removeExistingFlash();
    this.showFlashMessage(event.currentTarget);
    this.animateButton(event.currentTarget);
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.copyValueTarget.value);
  }

  removeExistingFlash() {
    const existingFlash = document.querySelector('.flash');
    if (existingFlash) {
      existingFlash.remove();
    }
  }

  showFlashMessage(triggerElement) {
    const flashMessage = this.createFlashMessage(triggerElement);
    this.insertFlashMessage(flashMessage);
  }

  createFlashMessage(triggerElement) {
    const flashMessage = document.createElement('div');
    flashMessage.className = 'flash flash--success py-1 text-center text-white border-t-2 border-b-2 border-white leading-tight relative z-10';
    flashMessage.setAttribute('role', 'alert');

    const innerDiv = document.createElement('div');
    innerDiv.className = 'mx-2 md:w-2/3 md:mx-auto';

    const messageText = document.createElement('p');
    messageText.className = 'text-lg my-5 font-medium';
    messageText.textContent = triggerElement.dataset.clipboardCopySuccessText;

    innerDiv.appendChild(messageText);
    flashMessage.appendChild(innerDiv);

    return flashMessage;
  }

  insertFlashMessage(flashMessage) {
    const flashContainer = document.querySelector('[data-clipboard-copy-flash-container]');
    if (flashContainer) {
      flashContainer.appendChild(flashMessage);
    }
  }

  animateButton(triggerElement) {
    triggerElement.classList.add("!border-green");

    setTimeout(() => {
      this.resetUI(triggerElement);
    }, 60000);
  }

  resetUI(triggerElement) {
    const flashMessage = document.querySelector('.flash');
    if (flashMessage) {
      flashMessage.remove();
    }
    triggerElement.classList.remove("!border-green");
  }
}
